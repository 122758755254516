import React from 'react'
import Template from '@app/components/template'
import { translate } from '@app/locale/translate'
import Select from 'react-select'
import { selectTheme } from '@app/components/utils'

import { getCities } from '@app/store'

import { Box, Button, Text } from 'rebass'

const Lookup = ({ cities }) => {
  const [URL, setURL] = React.useState()

  return (
    <Template>
      <Box width={[1, 2 / 3, 1 / 2]} mx='auto' textAlign='center'>
        <Text
          my={2}
          width={1}
          fontSize={[1, 2, 3]}
        >
          {translate("Looking to sign up for Make Music Day? Select your city here")}
        </Text>
        <Select
          classNamePrefix="react-select"
          instanceId='city-selector'
          theme={selectTheme}
          placeholder={translate('Select the city')}
          onChange={obj => setURL(obj.value)}
          options={cities.sort((a, b) => { return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) })
            .map(c => {
              return {
                value: c.base_url,
                label: c.name
              }
            })}
        />
        <Button mx='auto' width={240} mt={4} onClick={() => {
          if (URL)
            window.location = URL
        }}>
          {translate('Go to City')}
        </Button>
      </Box>

    </Template>
  )
}

Lookup.getInitialProps = async context => {
  const res = await getCities()
  return {
    cities: res.data ? res.data : []
  }
}

export default Lookup

